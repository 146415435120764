
























































// CORE
import { Component, Mixins } from 'vue-property-decorator'
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// LIBRARIES
import { Bind, Debounce } from 'lodash-decorators'
import { isEqual } from 'lodash'

// INTERFACES
import { CountResource, InsuranceStatisticsResource, ITableHeader, PaginationMeta } from '@/store/types'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'
import TableFooter from '@/components/TableFooter.vue'

//STORE
import InsuranceModule from '@/store/modules/insurance-statistics'
import { parseDate } from '@/utils/functions'

@Component({
  components: {
    CaptionCard,
    FiltersPanel,
    TableFooter,
  },
})

export default class InsuranceStatisticsTab extends Mixins(NotifyMixin, SystemMixin) {
  private isReady = false
  private isEmpty = false

  private get statuses (): CountResource[] {
    return InsuranceModule.statuses
  }

  private get headers (): ITableHeader[]{
    return InsuranceModule.tableHeaders
  }

  private get pagination (): PaginationMeta {
    return InsuranceModule.insurances.meta
  }

  private get tableData (): InsuranceStatisticsResource[] {
    return InsuranceModule.insurances.data.map(item => ({
      ...item,
      crashDate: parseDate(item.crashDate),
    }))
  }

  private get filter () {
    return InsuranceModule.filters
  }

  private set filter (value: any) {
    if (!isEqual(value, this.filter) || this.tableData.length === 0) {
      InsuranceModule.setFilters(value)
      this.fetchTableData()
    }
  }

  private get activeStatus (): string | undefined {
    return InsuranceModule.filters.status
  }

  private set activeStatus (value: string | undefined) {
    InsuranceModule.setActiveStatus(value)
    this.fetchTableData()
  }

  private created () {
    InsuranceModule.resetFilters()
    this.fetchTableData(true)
  }

  private goToCarInfo (item: any){
    this.$router.push({ name: 'car-info', params: { carId: item.id } })
  }

  private changeSortBy (sortBy: string | string[]) {
    InsuranceModule.setFilters({ ...this.filter, sortBy })
    this.fetchTableData()
  }

  private changeSortDesc (sortDesc: boolean | boolean[]) {
    InsuranceModule.setFilters({ ...this.filter, sortDesc })
    this.fetchTableData()
  }

  @Bind
  @Debounce(150)
  private fetchTableData (isFirst?: boolean) {
    InsuranceModule.getInsurances(+this.entity)
      .then(() => {
        if (isFirst && !this.tableData.length) {
          this.isEmpty = true
        }
      })
      .catch(this.notifyError)
      .finally(() => { this.isReady = true })
  }

  private beforeDestroy () {
    InsuranceModule.resetFilters()
  }
}
